window.esign = window.esign || {};

esign.init = function () {

  $('html').removeClass('no-js').addClass('js');

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [752, 0],
    lazy: true
  });

  esign.gaDevelopment();
  esign.cacheSelectors();

  //esign.navigation();
  esign.accessibleNav();
  esign.categoriesNav();
  esign.mobileNav();
  esign.responsiveVideos();
  esign.filterNavigation();
  esign.initDataTables();
  esign.quantitySelectors();
  esign.account();
  esign.terms();
  esign.shipping();
  esign.quickorder();
  esign.searchForm();
  esign.slick();
  esign.initScanner();
  esign.initMaps();
  // esign.infoModal();

  esign.referenceUpdate();
  esign.referenceAdd();

  esign.formValidation();
  esign.formAjax();
  esign.formAjaxRecaptcha();

  esign.handleCartUpdates();
};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    isMobile: esign.isMobile(),

    // navigation
    $nav: $('.main-nav__wrap')
  };
};

esign.navigation = function () {
  $('.main-nav__trigger').click(function (e) {
    e.preventDefault();
    $(this).next('.main-nav__wrap').slideToggle('fast');
  });

  Response.crossover('width', function () {
    if (Response.band(752)) {
      esign.cache.$nav.css('display', 'block');
    } else {
      esign.cache.$nav.css('display', 'none');
    }
  });
};

esign.accessibleNav = function () {
  var $nav = $('#AccessibleNav'),
    $allLinks = $nav.find('a'),
    $topLevel = $nav.children('li').find('a'),
    $parents = $nav.find('.main-nav__item--has-dropdown'),
    $categories = $('#navigation-categories'),
    $subMenuLinks = $nav.find('.main-nav__dropdown').find('a'),
    activeClass = 'main-nav__item--hover',
    focusClass = 'main-nav__link--focus';

  // Mouseenter
  $parents.on('mouseenter touchstart', function (evt) {
    var $el = $(this);

    if (!$el.hasClass(activeClass)) {
      evt.preventDefault();
    }

    showDropdown($el);
  });
  /*$categories.on('mouseenter touchstart', function(evt) {
    var $el = $(this);

    if (!$el.hasClass(activeClass)) {
      //evt.preventDefault();
    }

    showDropdown($el);
  });*/

  // Mouseout
  $parents.on('mouseleave', function () {
    hideDropdown($(this));
  });
  /*$categories.on('mouseleave', function() {
    hideDropdown($(this));
  });*/

  $subMenuLinks.on('touchstart', function (evt) {
    // Prevent touchstart on body from firing instead of link
    evt.stopImmediatePropagation();
  });

  $allLinks.focus(function () {
    //handleFocus($(this));
  });

  $allLinks.blur(function () {
    //removeFocus($topLevel);
  });

  // accessibleNav private methods
  function handleFocus($el) {
    var $subMenu = $el.next('ul'),
      hasSubMenu = $subMenu.hasClass('main-nav__subnav') ? true : false,
      isSubItem = $('.main-nav__dropdown').has($el).length,
      $newFocus = null;

    // Add focus class for top level items, or keep menu shown
    if (!isSubItem) {
      removeFocus($topLevel);
      addFocus($el);
    } else {
      $newFocus = $el.closest('.main-nav__link--dropdown').find('a');
      addFocus($newFocus);
    }
  }

  function showDropdown($el) {
    $el.addClass(activeClass);

    setTimeout(function () {
      $(document.body).on('touchstart', function () {
        hideDropdown($el);
      });
    }, 250);
  }

  function hideDropdown($el) {
    $el.removeClass(activeClass);
    $(document.body).off('touchstart');
  }

  function addFocus($el) {
    $el.addClass(focusClass);
  }

  function removeFocus($el) {
    $el.removeClass(focusClass);
  }
};

esign.categoriesNav = function () {
  /*$('.page-header__dropdown').on('mouseenter ', function(e) {
    $(this).addClass('page-header__dropdown--hover');
  });

  $('.page-header__dropdown').on('mouseleave', function(e) {
    $(this).removeClass('page-header__dropdown--hover');
  });*/

  $('#navigation-categories-trigger').on('click', function (e) {
    $(this).parents('.page-header__dropdown').toggleClass('page-header__dropdown--hover');
  });

  $(document).mouseup(function (e) {
    var container = $('.page-header__dropdown');

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass('page-header__dropdown--hover');
    }
  });

  $('.dropdown-categories .icons-list a').on('mouseenter', function (e) {

    var id = $(this).attr('data-content');
    console.log(id);

    $('.dropdown-categories .icons-list li').removeClass('active');
    $(this).parents('li').addClass('active');

    $('.dropdown-categories .main-nav__dropdown-subcontent').removeClass('active');
    $('.dropdown-categories .main-nav__dropdown-subcontent[data-content="' + id + '"]').addClass('active');
  });

};

esign.mobileNav = function () {
  $('.main-nav__trigger').modaal({
    content_source: '#mobile-navigation',
    fullscreen: true,
    custom_class: 'navigation-popup',
    animation: 'none'
  });
};

esign.referenceUpdate = function () {

  $('.update__trigger').modaal({
    content_source: '#update__modal'
  });

};

esign.referenceAdd = function () {
  $('.main-nav__trigger').modaal({
    content_source: '#mobile-navigation'
  });
};

esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};

esign.filterNavigation = function () {
  $('.filters-form__trigger').click(function (e) {
    e.preventDefault();
    $('.filters-form__content').toggle();
  });
};

esign.initDataTables = function () {
  $('.datatable').DataTable({
    "paging": false,
    "info": false,
    "searching": false,
    "ordering": true,
    "columnDefs": [{
      "targets": 'not-sortable',
      "orderable": false
    }]
  });
};

esign.quantitySelectors = function () {

  qtySelectors();

};

esign.account = function () {

  // add address form
  $('#address-filter').change(function () {
    target = $('#address-filter').val();
    $('.address-container').addClass('hidden');
    $('#address-' + target).removeClass('hidden');
  });

  $('#address-filter-create').click(function () {
    $('#address-filter').find('option:selected').prop('selected', false);
    $('#address-filter').find('option:disabled').prop('selected', true);
    $('.address-container').addClass('hidden');
    $('#address-0').removeClass('hidden');
  });

  // hide shipping address if same as billing address
  if ($('#check-copy-shipping-address').is(':checked')) {
    $('#form-copy-shipping-address').addClass('hidden');
  }
  $('#check-copy-shipping-address').change(function () {
    if (this.checked) {
      $('#form-copy-shipping-address').addClass('hidden');
    } else {
      $('#form-copy-shipping-address').removeClass('hidden');
    }
  })

};

esign.terms = function () {
  var $termsForm = $('.form.terms-form');

  $('input#terms').change(function () {
    var $form = $(this).parents('form');
    if ($(this).is(':checked')) {
      $form.find('#submit').prop("disabled", false);

    } else {
      $form.find('#submit').prop("disabled", true);
    }
  });

  if ($termsForm.length) {
    $termsForm.validationEngine('validate');
  }
};

esign.shipping = function () {
  $('select#delivery_method').change(function () {
    var $form = $(this).parents('form');

    var val = $(this).find('option:checked').val();
    if (val === 'pickup' || val === 'pickup_outside') {
      $form.find('.shipping-address-fields').slideUp();
    } else {
      $form.find('.shipping-address-fields').slideDown();
    }
  });
};

esign.quickorder = function () {
  $('.quickorder-table tbody').on('change input', '.quickorder-table__row input', function (event) {
    var $activeRow = $(this).parents('.quickorder-table__row');
    console.log('change input functon');
    if ($activeRow.is(':last-child')) {
      var result = $activeRow.find('input').filter(function () {
        return $.trim($(this).val()).length == 0;
      }).length == 0;
      console.log(result);
      if (result) {
        console.log('adds row');
        if ($activeRow.find('.formError').length) {
          $activeRow.find('.formError').remove();
        }
        esign.addQuickOrderRow();
      }
    }
  });

  $('.quickorder-table tbody').on('click', '.quickorder-table__remove', function (event) {
    event.preventDefault();
    $(this).parents('.quickorder-table__row').remove();
    // 2 and not 1 because there is also a template holder in the form
    if ($('.quickorder-table .quickorder-table__row').length < 2) {
      esign.addQuickOrderRow();
    }
  });
};

esign.addQuickOrderRow = function () {
  var $rowtemplate = $('#template-quickorder-table__row');
  var $rowtemplateClone = $rowtemplate.clone();
  $rowtemplateClone = $rowtemplateClone.show();
  $('.quickorder-table tbody').append($rowtemplateClone);
  esign.searchForm();
}

esign.searchForm = function () {

  // Search function in navigation
  $('.ui.search.main-search')
    .search({
      apiSettings: {
        url: base_url + lang + '/search/json?q={query}'
      },
      selectFirstResult: true,
      maxResults: 15,
      fields: {
        results: 'results',
        title: 'no',
        description: 'title',
        image: 'thumb',
        url: 'link'
      },
      searchFields: [
        'title',
        'description'
      ],
      minCharacters: 1,
      fullTextSearch: 'true'
    });

  // Search on quick order page
  $('.ui.search.default-search')
    .search({
      apiSettings: {
        url: base_url + lang + '/search/json?q={query}'
      },
      selectFirstResult: true,
      maxResults: 15,
      fields: {
        results: 'results',
        title: 'no',
        description: 'title',
      },
      searchFields: [
        'title',
        'description'
      ],
      minCharacters: 1,
      fullTextSearch: 'true',
    });

};

esign.setSessionStorage = function (key, val) {
  sessionStorage.setItem(key, val);
}

// var selectedDeviceId;
esign.openCamera = function (reader) {
  var selectedDeviceId;

  $('.barcode-modal__container').addClass('barcode-modal__container--show');

  reader.listVideoInputDevices()
    .then(function (videoInputDevices) {
        selectedDeviceId = videoInputDevices[0].deviceId;
        if (videoInputDevices.length >= 1) {
          videoInputDevices.forEach(function (element) {
            if (/back|rear|environment/gi.test(element.label)) {
              selectedDeviceId = element.deviceId;
            }
          });
        }
        // Listen for scannable barcodes
        esign.listenerScanner(reader, selectedDeviceId, 'barcodeCamera');
      })
    .catch(function (err) {
        console.error(err);
      })
}

// Reset camera & overlay
esign.resetCamera = function (reader) {
  $(".barcode-modal__overlay").hide();
  $('input[name="quantity"]').val(1);
  reader.reset();
}

// Listener for barcodes - sets input values and displays overlay on scan
esign.listenerScanner = function(reader, deviceId, element) {
  reader.decodeOnceFromVideoDevice(deviceId, element).then(function (res) {
      $(".barcode-modal__result").html(res.text);
      $(".barcode-modal__overlay").show();
  }).catch(function (err) {
      $(".barcode-modal__result").html(err.text);
  });
}

esign.addBarcodeToOrder = function () {
  var barcode = $('.barcode-modal__result').text();
  var barcodeQty = $('input[name="quantity"]').val();
  var barcodeInput = $('.quickorder-table input[name^="item_no"]').filter(function(){
    return this.value.length === 0;
  }).eq(1);
  var barcodeQtyInput = $('.quickorder-table input[name^="quantity"]').filter(function(){
    return this.value.length === 0;
  }).eq(1);

  barcodeInput.val(barcode);
  barcodeQtyInput.val(barcodeQty);

  if($('.quickorder-table input[name^="item_no"]').last().val().length != 0) {
    esign.addQuickOrderRow();
  }
}

esign.initScanner = function () {
  var reader = new ZXing.BrowserMultiFormatReader();

  if (reader) {
    $('#openCamera').modaal({
      content_source: '.barcode-modal',
      fullscreen: true,
      custom_class: 'barcode-popup',
      animation: 'none',
      close_text: $('#openCamera').attr("data-close-text"),
      width: window.innerWidth,
      height: window.innerHeight,
      before_open: function () {
        esign.openCamera(reader);
      },
      after_close: function () {
        esign.resetCamera(reader);
      }
    });

    $('#saveBarcode').on('click', function () {
      esign.addBarcodeToOrder(); // Save barcode to cart
      esign.resetCamera(reader);
      esign.openCamera(reader); // Reset & open camera again
    });

    $('#resetCamera').on('click', function () {
      esign.resetCamera(reader);
    });
  }
}

esign.slick = function () {

  var $carousel = $('.page-header__usp');

  $carousel.slick({
    dots: false,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    variableWidth: false,
    autoplay: true,
    mobileFirst: true,
    responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          autoplay: true
        }
      },
      {
        breakpoint: 1200,
        settings: 'unslick'
      }
    ]
  });

};



// ---------------------------------------
// ----------------- SHOPPING CART fn's --
// ---------------------------------------


esign.handleCartUpdates = function () {
  $('.cart-update-quantity').on('keyup change', function (e) {
    $input = $(this);

    $input.removeClass('updated');
    $input.addClass('changed');

    $cart_item = $input.parents('.cart__item');
    var postdata = $input.serializeArray();

    $.post(cart_url, postdata, function (data) {

      if (data.errors === false) {
        $input.removeClass('changed');
        $input.addClass('updated');
        $input.val(data.data.quantity);
        $cart_item.find('.cart-update-amount').text(data.data.line_amount);
        $cart_item.find('.cart-update-unit-price').text(data.data.unit_price);
        $cart_item.find('.cart-update-discount').text(data.data.discount);
        $cart_item.find('.cart-update-inventory').html(data.data.inventory);
        if (data.data.free_delivery_remaining) {
          $('.cart-update-remaining').show().html(data.data.free_delivery_remaining);
        } else {
          $('.cart-update-remaining').hide();
        }
        $('.cart-update-total').text(data.data.cart_total);
        $('.cart-update-transport').text(data.data.cart_transport);

        if (data.data.cart_show_transport) {
          $('.cart-transport').removeClass('hidden');
        } else {
          $('.cart-transport').addClass('hidden');
        }

        esign.updateCartTax($cart_item, data.data.quantity)
      } else {
        $input.removeClass('changed');
        $input.addClass('error');

      }

    });
  });
};

esign.updateCartTax = function ($cart_item, quantity) {
  select_item = $cart_item.data('cart-item');
  $('.cart__item-tax[data-cart-item="' + select_item + '"]').each(function (i, element) {
    tax_value = $(element).data('tax-value');
    $(element).find('.cart-update-tax-quantity').text(quantity);
    total = '&euro; ' + Number(tax_value * quantity).format(2, 10, ' ', ',');
    $(element).find('.cart-update-tax-amount').html(total);
  });
};


// ---------------------------------------
// ----------------- Maps ----------------
// ---------------------------------------

function addContactGoogleMaps(container, latitude, longitude) {

  var zoom = 15,
    disable = true,
    scroll = false,
    styledMap = new google.maps.StyledMapType(googleMapsStyles, {
      name: 'Styled Map'
    }),
    mapCenter = new google.maps.LatLng(latitude, longitude),
    mapOptions = {
      zoom: zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    },
    map = new google.maps.Map(document.getElementById(container), mapOptions);

  map.mapTypes.set('map_style', styledMap);
  map.setMapTypeId('map_style');

  google.maps.event.addDomListener(window, 'resize', function () {
    map.setCenter(mapCenter);
  });

  return map;
}

function addContactGoogleMapsMarker(map, latitude, longitude, title, externUrl) {
  var myLatlng = new google.maps.LatLng(latitude, longitude),
    markerIcon = {
      url: baseUrl + 'assets/images/marker.png',
      size: new google.maps.Size(100, 128),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(25, 64),
      scaledSize: new google.maps.Size(50, 64)
    },
    marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: markerIcon
    });

  google.maps.event.addListener(marker, 'click', function () {
    window.open(externUrl, '_blank');
  });

  return marker;
}

esign.initMaps = function () {
  var mapData = {
    title: 'Esign',
    lat: 51.0325538,
    long: 3.7333816,
    externUrl: 'https://www.google.be/maps/place/Esign+-+Web+%26+Graphics/@51.0325538,3.7333816,19z/data=!3m1!4b1!4m5!3m4!1s0x47c373970c763623:0xde317546f86febc9!8m2!3d51.0325538!4d3.7339288'
  };

  if ($('#map').length > 0) {
    var map = addContactGoogleMaps('map', mapData.lat, mapData.long);
    addContactGoogleMapsMarker(map, mapData.lat, mapData.long, mapData.title, mapData.externUrl);
  }
};

// ---------------------------------------
// ----------------- FORMS & Captcha -----
// ---------------------------------------

// normaal formulier versturen met post
esign.formAjax = function () {
  $('.form-ajax').submit(function (e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function (data) {
      if (data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// recaptcha formulier versturen met post
esign.formAjaxRecaptcha = function () {
  $('.form-ajax-recaptcha').submit(function (e) {
    e.preventDefault();
    var $form = $(this);

    if ($form.hasClass('validate')) {
      if ($form.validationEngine('validate') == false) {
        return false;
      }
    }

    if ($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != '') {
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

// recaptcha submit handler
esign.recaptchaFormSend = function (form) {
  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');

  $.ajax({
    type: 'POST',
    url: $form.attr('action'),
    data: new FormData($form[0]),
    processData: false,
    // Allows us to get file fields via JS
    contentType: false,
    success: function (data) {
      $('li').removeClass('error');
      var $result = $form.find('.result');

      if (data.errors === false) {
        $form.html(data.result);

        if (typeof ga === "function") {
          ga('send', 'pageview', $form.attr('action'));
        }

        //todo GTM trigger event

      } else {
        $result.html(data.result);
        if (data.fields) {
          $.each(data.fields, function (i, field) {
            $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
          });
        }
      }

      $form.find('input[type="submit"], button').removeAttr('disabled');
    }
  }).fail(function (response) {
    alert('Error: ' + response.responseText);
  });
};

// recaptcha submit handler per formulier
var submitRecaptchaFormContact = function (token) {
  esign.recaptchaFormSend($('#form-contact'));
};
var submitRecaptchaFormNewsletter = function (token) {
  esign.recaptchaFormSend($('#form-newsletter'));
};

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
var onloadReCaptchaCallback = function () {
  $('.g-recaptcha').each(function (i, el) {
    var attributes = {
      'sitekey': $(el).data('sitekey'),
      'size': $(el).data('size'),
      'callback': $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid', widgetid);
  });
};


// ---------------------------------------
// ----------------- FORMS validation ----
// ---------------------------------------

esign.formValidation = function () {
  if (esign.cache.isMobile) {
    $('.validate').validationEngine();
  } else {
    $('.validate').validationEngine({
      scroll: false
    });
  }
};


// ---------------------------------------
// ----------------- UTILS -----------
// ---------------------------------------
esign.isMobile = function () {
  var deviceAgent = navigator.userAgent.toLowerCase(),
    isMobile = (deviceAgent.match(/(iphone|ipod|ipad)/) ||
      deviceAgent.match(/(android)/) ||
      deviceAgent.match(/(iemobile)/) ||
      deviceAgent.match(/blackberry/i) ||
      deviceAgent.match(/bada/i)) ||
    (/OS [1-4]_[0-9_]+ like Mac OS X/i.test(navigator.userAgent));

  if (isMobile) {
    $('html').addClass('mobile');
  } else {
    $('html').addClass('no-mobile');
  }

  return isMobile;
};


// ---------------------------------------
// ----------------- DEV TOOLS -----------
// ---------------------------------------

// Log ga calls in development
esign.gaDevelopment = function () {
  if (typeof ga === typeof undefined) {
    window.ga = function () {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

require([
  'requirejs/require',
  'jquery/dist/jquery',
  'datatables.net',
  'js/libs/plugins/handlebars.min',
  'js/libs/plugins/qty-selector',
  'js/libs/plugins/jquery-ui',
  'js/libs/plugins/api',
  'js/libs/plugins/search',
  'js/libs/plugins/modaal',
  'js/libs/plugins/slick',
  'js/libs/plugins/jquery.scrollTo',
  'js/polyfills/native-console',
  'response.js/response',
  'jquery-touchswipe/jquery.touchSwipe',
  'js/libs/validation/languages/jquery.validationEngine-nl',
  'js/libs/validation/jquery.validationEngine',
  'js/googlemaps-styles',
], function () {
  // Initialize on docready
  $(esign.init);
});

define("../resources/assets/js/esign", function(){});

