qtySelectors = function() {
  // Change number inputs to JS ones, similar to ajax cart but without API integration.
  // Make sure to add the existing name and id to the new input element
  var numInputs = $('input[type="number"].js-qty');

  if (numInputs.length) {
    numInputs.each(function() {
      var $el = $(this),
        currentQty = $el.val(),
        inputName = $el.attr('name'),
        inputId = $el.attr('id');
        inputClass = $el.attr('class').replace("js-qty", "");

      var itemAdd = currentQty + 1,
        itemMinus = currentQty - 1,
        itemQty = currentQty;

      var source   = $("#JsQty").html(),
        template = Handlebars.compile(source),
        data = {
          key: $el.data('id'),
          itemQty: itemQty,
          itemAdd: itemAdd,
          itemMinus: itemMinus,
          inputName: inputName,
          inputId: inputId,
          inputClass: inputClass
        };

      // Append new quantity selector then remove original
      $el.after(template(data)).remove();
    });

    // Setup listeners to add/subtract from the input
    $('.js-qty__adjust').on('click', function() {
      var $el = $(this),
        id = $el.data('id'),
        $qtySelector = $el.siblings('.js-qty__num'),
        qty = parseInt($qtySelector.val().replace(/\D/g, ''));

      var qty = validateQty(qty);

      // Add or subtract from the current quantity
      if ($el.hasClass('js-qty__adjust--plus')) {
        qty += 1;
      } else {
        qty -= 1;
        if (qty <= 1) qty = 1;
      }

      // Update the input's number
      $qtySelector.val(qty);
      $qtySelector.trigger('change');
    });
  }
};

validateQty = function (qty) {
  if((parseFloat(qty) == parseInt(qty)) && !isNaN(qty)) {
    // We have a valid number!
  } else {
    // Not a number. Default to 1.
    qty = 1;
  }
  return qty;
};

define("js/libs/plugins/qty-selector", function(){});

